// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-components-about-us-js": () => import("./../../../src/pages/components/about-us.js" /* webpackChunkName: "component---src-pages-components-about-us-js" */),
  "component---src-pages-components-block-js": () => import("./../../../src/pages/components/block.js" /* webpackChunkName: "component---src-pages-components-block-js" */),
  "component---src-pages-components-block-left-js": () => import("./../../../src/pages/components/block-left.js" /* webpackChunkName: "component---src-pages-components-block-left-js" */),
  "component---src-pages-components-cards-js": () => import("./../../../src/pages/components/cards.js" /* webpackChunkName: "component---src-pages-components-cards-js" */),
  "component---src-pages-components-clients-js": () => import("./../../../src/pages/components/clients.js" /* webpackChunkName: "component---src-pages-components-clients-js" */),
  "component---src-pages-components-footer-js": () => import("./../../../src/pages/components/footer.js" /* webpackChunkName: "component---src-pages-components-footer-js" */),
  "component---src-pages-components-form-js": () => import("./../../../src/pages/components/form.js" /* webpackChunkName: "component---src-pages-components-form-js" */),
  "component---src-pages-components-hero-js": () => import("./../../../src/pages/components/hero.js" /* webpackChunkName: "component---src-pages-components-hero-js" */),
  "component---src-pages-components-navbar-js": () => import("./../../../src/pages/components/navbar.js" /* webpackChunkName: "component---src-pages-components-navbar-js" */),
  "component---src-pages-components-seo-js": () => import("./../../../src/pages/components/seo.js" /* webpackChunkName: "component---src-pages-components-seo-js" */),
  "component---src-pages-components-social-js": () => import("./../../../src/pages/components/social.js" /* webpackChunkName: "component---src-pages-components-social-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

